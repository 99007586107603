<form *ngIf="parentFormGroup" [formGroup]="parentFormGroup" [ngClass]="{'readonly': readonly}">
	<div class="row mb-2">
		<div class="col-lg-12">
			<h5 class="my-3 text-mad text-uppercase">General valuation matters</h5>
			<hr class="active">
		</div>
	</div> 
	<ng-container *ngIf="groupedValuations.length > 1">
		<ng-container *ngIf="hasManyScenario(groupedValuations)">
		<div class="row mb-20">
			<div class="col-lg-12">
				<h6 class="text-mad mb-5">
					{{numbering[0]}} Choose the scenario you want to use for each valuation.
				</h6>
				<div class="row mb-2" *ngFor="let gVal of groupedValuations">
					<ng-container *ngIf="gVal.valuations.length > 1">
						<div class="col-lg-5">
							<div class="text-mad">Valuation {{gVal.valuation_index + 1}} - {{gVal.valuations[0].valuation.base_of_value_name}} - {{gVal.valuations[0].valuation.methods_to_value_name}} </div>
						</div>
						<div class="col-lg-6">
								<mat-radio-group [formControl]="valuationScenarioControl(gVal.valuation_index)">
									<mat-radio-button *ngFor="let val of gVal.valuations" [value]="val.valuation.id">
										Scenario {{val.scenario.scenario_number}}
									</mat-radio-button>
								</mat-radio-group>
						</div>
						<div class="col-lg-12" *ngIf="showJustificationField[gVal.valuation_index] && showJustificationField[gVal.valuation_index].show">
							<div style="display: flex; flex-direction: row; align-items: center;">
									<mat-icon style="height: 12px; color: #F3EC24; font-size: 12px !important; margin-right: 10px;">warning</mat-icon>
									<p style="color: red; margin-bottom: 0;">The current methodology ({{showJustificationField[gVal.valuation_index].current}}) has changed from the original methodology ({{showJustificationField[gVal.valuation_index].original}}) outlined in the Terms of Reference.</p>
							</div>
							<mat-form-field class="mat-form-field-fluid">
								<textarea matInput [placeholder]="'Please explain why'"
									[formControl]="valuationScenarioJustificationControl(gVal.valuation_index)">
								</textarea>
								<mat-error>
									Required
								</mat-error>
							</mat-form-field>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
		<div class="row mb-20">
			<div class="col-lg-12">
				<h6 class="text-mad mb-5">
					{{numbering[1]}} - Choose the value to feature in the report.
				</h6>
				<div class="row">
					<div class="col-lg-12">
						<mat-radio-group formControlName="valuation">
							<mat-radio-button *ngFor="let val of _chooseValuations; let i = index" [value]="val.id">
								Valuation {{i+1}} - {{val.base_of_value_name}} - {{val.methods_to_value_name}}
							</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="col-lg-12">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput [placeholder]="'Justify your choosing (minimum 20 characters)'" 
								formControlName="valuation_justification" minlength="20">
							<mat-error *ngIf="valuationJustificationControl.hasError('required')">
								Required
							</mat-error>
							<mat-error *ngIf="valuationJustificationControl.hasError('minlength')">
								It must be at least 20 characters long
							</mat-error>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		</ng-container>
		<ng-container *ngIf="hasOnlyOneScenario(groupedValuations)">
		<div class="row mb-20">
			<div class="col-lg-12">
				<h6 class="text-mad mb-5">
					{{numbering[1]}} - Choose the value to feature in the report.
				</h6>
				<div class="row">
					<div class="col-lg-12">
						<mat-radio-group formControlName="valuation">
							<mat-radio-button *ngFor="let val of _chooseValuations; let i = index" [value]="val.id">
								Valuation {{i+1}} - {{val.base_of_value_name}} / {{val.methods_to_value_name}}
							</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="col-lg-12">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput [placeholder]="'Justify your choosing (minimum 20 characters)'" 
								formControlName="valuation_justification" minlength="20">
							<mat-error *ngIf="valuationJustificationControl.hasError('required')">
								Required
							</mat-error>
							<mat-error *ngIf="valuationJustificationControl.hasError('minlength')">
								It must be at least 20 characters long
							</mat-error>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="groupedValuations.length == 1">
		<ng-container *ngIf="hasManyScenario(groupedValuations)">
			<div class="row mb-20">
				<div class="col-lg-12">
					<h6 class="text-mad mb-5">
						{{numbering[1]}} Choose the scenario you want to use for the valuation
					</h6>
					<div class="row mb-2">
						<div class="col-lg-12">
							<mat-radio-group [formControl]="valuationScenarioControl(groupedValuations[0].valuation_index)">
								<mat-radio-button *ngFor="let val of groupedValuations[0].valuations" [value]="val.valuation.id">
									Scenario {{val.scenario.scenario_number}}
								</mat-radio-button>
							</mat-radio-group>
						</div>
						<div class="col-lg-12" *ngIf="showJustificationField[groupedValuations[0].valuation_index] && showJustificationField[groupedValuations[0].valuation_index].show">
							<mat-form-field class="mat-form-field-fluid">
								<textarea matInput [placeholder]="'Please explain why'"
									[formControl]="valuationScenarioJustificationControl(groupedValuations[0].valuation_index)">
								</textarea>
								<mat-error>
									Required
								</mat-error>
							</mat-form-field>
						</div>
					</div>
				</div>
			</div>
		</ng-container>	
	</ng-container>
	<div class="row mb-20">
		<div class="col-lg-12">
			<h6 class="text-mad mb-5">
				{{numbering[2]}} - Valuation date
				<button
					mat-icon-button
					container="body" 
					[popoverTitle]="'TOOLTIP.REPORTING_PROCESS.VALUATION_DATE.TITLE'|translate"
					[ngbPopover]="'TOOLTIP.REPORTING_PROCESS.VALUATION_DATE.DESCRIPTION'|translate">
					<mat-icon>
						help
					</mat-icon>
				</button>
			</h6>
			<div class="row">
				<div class="col-lg-4">
					<mat-form-field class="mat-form-field-fluid">
						<kt-datepicker-tz-input
							#valuationDate
							formControlName="valuation_date"
							[timezone]="toe.timezone"
							></kt-datepicker-tz-input>
						<!-- <span (click)="valuationDate.open()">
							<input matInput [matDatepicker]="valuationDate" readonly formControlName="valuation_date" />
						</span> -->
						<!-- <mat-datepicker-toggle matSuffix [for]="valuationDate"></mat-datepicker-toggle>
						<button mat-button matSuffix mat-icon-button matTooltip="Clear" 
							*ngIf="valuationDateControl.value"
							(click)="clearDate(valuationDateControl)">
							<mat-icon>close</mat-icon>
						</button>
						<mat-datepicker #valuationDate></mat-datepicker>
						<mat-error>Required</mat-error> -->

						<button mat-button mat-icon-button matSuffix type="button">
								<mat-icon>today</mat-icon>
						</button>
						<button mat-button mat-icon-button matSuffix type="button" (click)="valuationDate.clearDate()">
								<mat-icon>close</mat-icon>
						</button>
						<mat-error>
							required
						</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-20">
		<div class="col-lg-12">
			<h6 class="text-mad mb-5">
				{{numbering[3]}} - Add Signature Placeholder
				<button
					mat-icon-button
					container="body" 
					[popoverTitle]="'TOOLTIP.REPORTING_PROCESS.SIGNATURE.TITLE'|translate"
					[ngbPopover]="'TOOLTIP.REPORTING_PROCESS.SIGNATURE.DESCRIPTION'|translate">
					<mat-icon>
						help
					</mat-icon>
				</button>
			</h6>
			<div class="row">
				<div class="col-lg-12">
					<mat-radio-group formControlName="has_team_signatures">
						<mat-radio-button [value]="0">No</mat-radio-button>
						<mat-radio-button [value]="1">Yes</mat-radio-button>
					</mat-radio-group>
				</div>
				<ng-container *ngIf="hasTeamSignaturesControl.value == 1" >
					<ng-container *ngFor="let member of members">
						<div class="col-lg-4 mt-4">
							<mat-checkbox [formControl]="signatureControl(member)">
								{{member.fullName}} <span *ngIf="member.qualification">({{member.qualification}})</span>
							</mat-checkbox>
						</div>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>
	<div class="row mb-20">
		<div class="col-lg-12">
			<h6 class="text-mad mb-5">
				{{numbering[4]}} - Change in market conditions between valuation and reporting dates?
			</h6>
			<div class="row">
				<div class="col-lg-12">
					<mat-radio-group formControlName="change_in_market">
						<mat-radio-button [value]="0">No</mat-radio-button>
						<mat-radio-button [value]="1">Yes</mat-radio-button>
					</mat-radio-group>
				</div>
				<div class="col-lg-12" *ngIf="changeInMarketControl.value == 1">
					<mat-form-field class="mat-form-field-fluid">
						<input matInput [placeholder]="'Explain the changes (minimum 20 characters)'"
							formControlName="change_in_market_justification" minlength="20" />
						<mat-error *ngIf="changeInMarketJustificationControl.hasError('required')">
							Required
						</mat-error>
						<mat-error *ngIf="changeInMarketJustificationControl.hasError('minlength')">
							It must be at least 20 characters long
						</mat-error>
					</mat-form-field>
				</div>
			</div>
		</div>
	</div>
	<div class="row mb-20">
		<div class="col-lg-12">
			<div style="display: flex; align-items: center;">
				<h6 class="text-mad mb-0">
					{{numbering[5]}} - Use round up value?
				</h6>
				<div class="ml-2">
					<mat-checkbox formControlName="use_round_up"></mat-checkbox>
				</div>
			</div>
		</div>
	</div>
	<ng-container *ngIf="draftReport">
		<div class="row mb-20">
			<div class="col-lg-12">
				<h6 class="text-mad mb-5">
					{{numbering[6]}} - A draft valuation report has been generated ({{draftReport.created_at | date: 'dd MMM yyyy'}}). Has it been shared with the client?
				</h6>
				<div class="row">
					<div class="col-lg-12">
						<mat-radio-group formControlName="draft_is_shared_to_client">
							<mat-radio-button [value]="0">No</mat-radio-button>
							<mat-radio-button [value]="1">Yes</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
			</div>
		</div>
		<div class="row mb-20" *ngIf="draftIsSharedControl.value == 1">
			<div class="col-lg-12">
				<h6 class="text-mad mb-5">
					{{numbering[7]}} - Has there been a material change in the valuation after submission of the draft report to the client? 
				</h6>
				<div class="row">
					<div class="col-lg-12">
						<mat-radio-group formControlName="has_material_change">
							<mat-radio-button [value]="0">No</mat-radio-button>
							<mat-radio-button [value]="1">Yes</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
			</div>
		</div>
		<div class="row mb-5" *ngIf="hasMaterialChangeControl.value == 1">
			<div class="col-lg-12">
				<div class="warning-text">
					<p>To demonstrate that the discussions with the client have not compromised the valuer's independence, please use the Audit Trail tool to record the following:</p>
					<p>- the information provided, or the suggestions made, in relation to the valuation</p>
					<p>- how that information was used to consider a change in material matters or opinions and</p>
					<p>- the reasons why the valuation has or has not been changed.</p>
				</div>
			</div>
		</div>
	</ng-container>
	<div class="row mb-20" *ngIf="targetProperty.country_currency !== targetProperty.reporting_currency">
		<div class="col-lg-12" formGroupName="val_exchange">
			<div class="mb-10">
				<h6 class="text-mad">{{numbering[8]}} - Currency Exchange rates used for the report.</h6>
				<p>The country currency is <span class="in-red">{{targetProperty.country_currency}}</span> while the reporting currency is <span class="in-red">{{targetProperty.reporting_currency}}</span>. Basis of exchange rate stipulated in the ToE: {{targetProperty.currency_exchange_rate}}</p>
			</div>
			<div *ngFor="let val of valuationData; let i = index">
				<div class="row mb-5">
					<!-- <div class="col-lg-12">
						<p style="font-size: 10px">
							Valuation {{i + 1}} - Exchange rate ({{targetProperty.country_currency}}/{{targetProperty.reporting_currency}}) (valuation date: {{valuationDateControl.value | date:'dd MMM yyyy'}})
						</p>
					</div> -->
					<div class="col-lg-4 currency-exchange-section">
						<div class="base">
							1 {{baseCurrency[val.val.id]}}
						</div>
						<button mat-icon-button (click)="reverseExchangeStatus(val.val.id, val.totalValue)">
							<mat-icon>swap_horiz</mat-icon>
						</button>
						<div class="quote">
							{{quoteCurrency[val.val.id]}}
							<!-- <mat-form-field class="mat-form-field-fluid" appearance="fill">  -->
								<input width="10" type="text" matInput [formControlName]="val.val.id + '_exchange_rate'"
									(focusout)="onFocusOut($event, val.val.id, val.totalValue)"> 
							 <!-- </mat-form-field>  -->
						</div>
					</div>
					<div class="col-lg-6 result">
						Valuation {{i+1}} ({{val.totalValue.toFixed(2) | mask:'separator':{thousandSeparator: ','} }} {{targetProperty.country_currency}}):
						<span class="in-red" style="margin-left: 1em;">{{convertedValues[val.val.id] ? (convertedValues[val.val.id].toFixed(2) | mask:'separator':{thousandSeparator: ','} ) : 'N/A'}} {{targetProperty.reporting_currency}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<ng-container *ngIf="toe.purpose_of_valuation_id == 13">
		<div class="row mb-20">
			<div class="col-md-12">
				<h6 class="text-mad mb-10">
					{{numbering[9]}} - Market identification and input levels
					<mat-icon matSuffix
						class="cursor-pointer icon-gray pl-2 mt-1"
						container="body"
						[ngbPopover]="'TOOLTIP.REPORTING_PROCESS.MARKET_IDENTIFICATION.DESCRIPTION' | translate"
						[popoverTitle]="'TOOLTIP.REPORTING_PROCESS.MARKET_IDENTIFICATION.TITLE' | translate"
						(click)="$event.stopPropagation()">
						help
					</mat-icon>
				</h6>
				<div class="row">
					<div class="col-lg-12">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput [placeholder]="'Explain'"
								formControlName="market_identification_n_input_levels" />
							<mat-error>Required</mat-error>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
		<div class="row mb-20">
			<div class="col-md-12">
				<h6 class="text-mad mb-5">
					{{numbering[10]}} - Is the basis of value adopted consistent with the basis of market value? 
				</h6>
				<div class="row">
					<div class="col-lg-12">
						<mat-radio-group formControlName="base_of_value_adopted">
							<mat-radio-button [value]="0">No</mat-radio-button>
							<mat-radio-button [value]="1">Yes</mat-radio-button>
						</mat-radio-group>
					</div>
					<div class="col-lg-12" *ngIf="baseOfValueAdoptedControl.value == 0">
						<mat-form-field class="mat-form-field-fluid">
							<input matInput [placeholder]="'Please specify'"
								formControlName="base_of_value_adopted_desc"/>
							<mat-icon matSuffix
								class="cursor-pointer icon-gray pl-2 mt-1"
								container="body"
								ngbPopover="Please use the following field to describe how the application of the selected basis of value would lead to different values than market value"
								(click)="$event.stopPropagation()">
								help
							</mat-icon>
							<mat-error>Required</mat-error>
						</mat-form-field>
					</div>
				</div>
			</div>
		</div>
	</ng-container>
	<ng-container *ngIf="reportingSpecialAssumptionFormArray.length > 0">
		<div class="row mb-20">
			<div class="col-12">
				<h6 class="text-mad mb-5">
					{{numbering[11]}} - Special assumptions
				</h6>
				<div class="row">
					<ng-container *ngFor="let group of reportingSpecialAssumptionFormArray.controls">
						<div class="col-12" [formGroup]="group">
							<div class="row">
								<p class="col-12">- {{ group.controls.input.value }}</p>
								<mat-form-field class="col-12 mat-form-field-fluid">
									<input matInput placeholder="Please comment on the impact of the special assumption on the valuation" formControlName="comment" />
								</mat-form-field>
							</div>
						</div>
					</ng-container>
				</div>
			</div>
		</div>
	</ng-container>
</form>