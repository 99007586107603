import { Component, Input, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { filter } from "rxjs/operators";
import { AssetClassModel, ValuationModel } from "src/app/core/asset_class";
import { currentUser } from "src/app/core/mad-auth/mad-auth.store";
import { ValuationProcessService } from "src/app/core/valuation-process";

@Component({
  selector: 'kt-income-valuation-summary-table',
  templateUrl: './income-valuation-summary-table.component.html',
  styleUrls: ['./income-valuation-summary-table.component.scss']
})
export class IncomeValuationSummaryTableComponent implements OnInit {
  @Input()
  targetProperty: AssetClassModel;
  @Input()
  valuation: ValuationModel;

  viewModel$: Observable<any>;

  constructor(
    private valuationProcessService: ValuationProcessService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.viewModel$ = this.valuationProcessService.summaryData(this.targetProperty.id, this.valuation.id).pipe(
      filter(value => value.kind == 'income')
    )
  }
}