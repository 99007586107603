export type ESGIndicatorRemote = {
  energy_performance_certificate: string,
  primary_energy_consumption: number,
  primary_energy_consumption_rate: string,
  performance: number,
  performance_rate: string,
  final_energy_consumption: number,
  final_energy_consumption_rate: string,
  energy_intensity: number,
  energy_intensity_rate: string,
  co2_emissions: number,
  co2_emissions_rate: string,
  expiry_date: string,
  improvements_since_last_energy_rating: string,
  comments_or_justifications: string
}
export type ESGIndicator = {
  energyPerformanceCertificate: string,
  primaryEnergyConsumption: number,
  primaryEnergyConsumptionRate: string,
  performance: number,
  performanceRate: string,
  finalEnergyConsumption: number,
  finalEnergyConsumptionRate: string,
  energyIntensity: number,
  energyIntensityRate: string,
  co2Emissions: number,
  co2EmissionsRate: string,
  expiryDate: string,
  improvementsSinceLastEnergyRating: string,
  commentOrJustifications: string
}

export function mapRemoteESGIndicatorToModel(remote: ESGIndicatorRemote): ESGIndicator {
  if (remote == null || remote == undefined) {
    return {
      energyPerformanceCertificate: null,
      primaryEnergyConsumption: null,
      primaryEnergyConsumptionRate: null,
      performance: null,
      performanceRate: null,
      finalEnergyConsumption: null,
      finalEnergyConsumptionRate: null,
      energyIntensity: null,
      energyIntensityRate: null,
      co2Emissions: null,
      co2EmissionsRate: null,
      expiryDate: null,
      improvementsSinceLastEnergyRating: null,
      commentOrJustifications: null
    }
  }
  return {
    energyPerformanceCertificate: remote.energy_performance_certificate,
    primaryEnergyConsumption: remote.primary_energy_consumption,
    primaryEnergyConsumptionRate: remote.primary_energy_consumption_rate,
    performance: remote.performance,
    performanceRate: remote.performance_rate,
    finalEnergyConsumption: remote.final_energy_consumption,
    finalEnergyConsumptionRate: remote.final_energy_consumption_rate,
    energyIntensity: remote.energy_intensity,
    energyIntensityRate: remote.energy_intensity_rate,
    co2Emissions: remote.co2_emissions,
    co2EmissionsRate: remote.co2_emissions_rate,
    expiryDate: remote.expiry_date ? getFormattedDate(new Date(remote.expiry_date)) : null,
    improvementsSinceLastEnergyRating: remote.improvements_since_last_energy_rating,
    commentOrJustifications: remote.comments_or_justifications
  }
}

function getFormattedDate(date: Date) {
  let year = date.getFullYear();
  let month = (1 + date.getMonth()).toString().padStart(2, '0');
  let day = date.getDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
}