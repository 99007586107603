import { ESGIndicator } from "src/app/core/valuation-process/_models/esg-indicator.model";
import { VPropertySubTypes } from "src/app/core/valuation-process/_models/valuation-process-target-property.model";
import { Row, Cells, simpleCellCreator, mergeRRows, mergeRows } from "./comparable-info-dialog.types";
import { NgxMaskPipe } from "ngx-mask";

const items: { header: string, field: keyof ESGIndicator , otherField?: keyof ESGIndicator}[] = [
  { header: 'Energy Performance Certificate', field: 'energyPerformanceCertificate' },
  { header: 'Primary Energy Consumption', field: 'primaryEnergyConsumption', otherField: 'primaryEnergyConsumptionRate' },
  { header: 'Performance', field: 'performance', otherField: 'performanceRate' },
  { header: 'Final Energy Consumption', field: 'finalEnergyConsumption', otherField: 'finalEnergyConsumptionRate' },
  { header: 'Energy Intensity', field: 'energyIntensity', otherField: 'energyIntensityRate'},
  { header: 'CO2 Emissions', field: 'co2Emissions', otherField: 'co2EmissionsRate'},
  { header: 'Expiry Date', field: 'expiryDate' },
  { header: 'Improvements Since last energy rating', field: 'improvementsSinceLastEnergyRating'},
  { header: 'Comments / Justifications', field: 'commentOrJustifications'}
]

export function esgIndicatorRows(assetClasses: {
  refNum: string,
  propertySubType: VPropertySubTypes,
  propertySubTypeName: string,
  esgIndicator: ESGIndicator,
}[], maskPipe: NgxMaskPipe): Row[] {
  const rrows = assetClasses.map(assetClasses => {
    return items.map(item => {
      const cells: Cells = {}
      cells[assetClasses.refNum] = !item.otherField
        ? simpleCellCreator(assetClasses.esgIndicator[item.field])
        : (assetClasses.esgIndicator[item.field] 
            ? simpleCellCreator(`${maskPipe.transform(assetClasses.esgIndicator[item.field], 'separator.2', {thousandSeparator: ','})} ${assetClasses.esgIndicator[item.otherField]}`)
            : { kind: 'na' }
          )
      return {
        header: item.header,
        cells,
        propertySubType: assetClasses.propertySubTypeName
      }
    })
  })
  const mergedRows = mergeRRows(rrows)
  return mergeRows(mergedRows, assetClasses.map(ac => ac.refNum))
}