import { ValuationProcessTargetPropertyRemote } from "../_services/valuation-process.service"
import { ESGIndicator, ESGIndicatorRemote, mapRemoteESGIndicatorToModel } from "./esg-indicator.model"
import { VBuilding } from "./valuation-asset-class-building.model"
import { displayConsiderations, mapConsiderationRemoteToDomain, mapVAssetClassSizeRemoteToDomain, mapVLocationRemoteToDomain, VAssetClassConsideration, VAssetClassConsiderationRemote, VAssetClassSize, VAssetClassSizeRemote, VLocationData, VLocationDataRemote } from "./valuation-asset-class-common.models"
import { mapVParcelIdentificationRemoteToDomain, VParcelIdentification, VParcelIdentificationRemote } from "./valuation-land-parcel.model"
import { PictureModel, TypeOfInspection, ValuationProcessParkingModel } from "./valuation-process-target-property.model"

export type VParkingModel = {
    refNum: string,
    propertySubType: 'Parking'
    propertySubTypeName: string

    locationData: VLocationData
    property: VParkingAboutProperty
    building: VBuilding

    pictures: PictureModel[]
    sizes: VAssetClassSize[],

    oldBuildingInfo: any

    parcelIdentification: VParcelIdentification,

    esgIndicator: ESGIndicator
}

export type VParkingAboutProperty = {
    subTypeCategory: string,
    subCategory: string,
    stateOfRepair: string,
    handoverStandard: string,
    floors: string,
    generalDescription: string,
    numberOfUnits: number,
    parkingType: string,
    automation: string
}

export type VCParkingModel = VParkingModel & {
    id: number

    // Info
    considerations: VAssetClassConsideration[]
    displayConsiderations: VAssetClassConsideration[]

    // Additional
    status: number,
    usedTimes: number,
    propertyTypeName: string
}

export type VTPParkingModel = VParkingModel & {
    refNum: 'tp',
    inspectionInfo: {
        typeOfInspection: TypeOfInspection,
        inspectionDate: Date
        anyLimitationOrRestriction: boolean,
        limitationDescription: string 
    },
}

export function mapVTPParkingRemoteToDomain(tpRemote: ValuationProcessTargetPropertyRemote, generalInfo: ValuationProcessParkingModel): VTPParkingModel {
    return {
        refNum: 'tp',
        propertySubType: generalInfo.propertySubType,
        propertySubTypeName: tpRemote.propertySubTypeName,
        locationData: {
            latitude: generalInfo.locationData.latitude,
            longitude: generalInfo.locationData.longitude,
            country: generalInfo.locationData.countryName,
            city: generalInfo.locationData.cityName,
            zipCode: generalInfo.locationData.zipCode,
            address: generalInfo.locationData.address,
            locationGrade: generalInfo.locationGrade == 'N/A' ? 'Unknown' : generalInfo.locationGrade,
            locationSurrounding: tpRemote.locationSurrounding,
            timezoneOffset: tpRemote.locationData?.timezoneOffset
        },
        inspectionInfo: {
            typeOfInspection: tpRemote.inspectionTypeId,
            inspectionDate: new Date(tpRemote.inspectionDate),
            anyLimitationOrRestriction: tpRemote.anyLimitationsOrRestrictions,
            limitationDescription: tpRemote.limitationDesc
        },
        property: {
            subCategory: tpRemote.subCategoryName == 'N/A' ? null : tpRemote.subCategoryName,
            subTypeCategory: tpRemote.subTypeCategoryName == 'N/A' ? null : tpRemote.subTypeCategoryName,
            stateOfRepair: tpRemote.aboutProperty.stateOfRepairName,
            handoverStandard: tpRemote.aboutProperty.handoverStandardName,
            generalDescription: tpRemote.aboutProperty.generalDescription,
            floors: tpRemote.aboutProperty.floorLocation,
            numberOfUnits: tpRemote.aboutProperty.number_of_units,
            parkingType: tpRemote.aboutProperty.parking_type,
            automation: tpRemote.aboutProperty.automation,
        },
        building: tpRemote.buildingInfo,
        oldBuildingInfo: tpRemote.oldBuildingInfo,
        parcelIdentification: mapVParcelIdentificationRemoteToDomain(tpRemote.parcel_identification),
        pictures: tpRemote.pictures,
        sizes: mapVAssetClassSizeRemoteToDomain(tpRemote.sizes),
        esgIndicator: mapRemoteESGIndicatorToModel(tpRemote.esgIndicator)
    }
}

export type VCParkingRemote = {
    id: number,
    property_sub_type_id: 2;

    // Info
    ref_num: string,
    location_data: VLocationDataRemote,
    sub_type_category_name: string,
    sub_category_name: string,
    general_description: string,
    state_of_repair_name: string,
    handover_standard_name: string,
    floor_location: string,
    number_of_units: number,
    parking_type_name: string,
    automation: string,

    sizes: VAssetClassSizeRemote[],
    considerations: VAssetClassConsiderationRemote[],
    pictures: PictureModel[],
    parcel_identification: VParcelIdentificationRemote

    buildingInfo: {
        name: string,
        buildingType: string,
        completionYear: string,
        buildingTypeComment: string,
        buildingGrade: string,
        energyEfficiencyGrade: string,
        developer: string,
        anchorTenant: string,
        foundationType: string,
        buildingDescription: string,
        pictures: PictureModel[],
    },

    // Additional
    status: number,
    used_times: number,
    property_sub_type_name: string,
    property_type_name: string,

    oldBuildingInfo: any,

    esgIndicator: ESGIndicatorRemote
}

export function mapVCParkingRemoteToDomain(remote: VCParkingRemote): VCParkingModel {
    const considerations = mapConsiderationRemoteToDomain(remote.considerations)
    return {
        id: remote.id,
        refNum: remote.ref_num,
        propertySubType: 'Parking',
        locationData: mapVLocationRemoteToDomain(remote.location_data),
        property: {
            subTypeCategory: remote.sub_type_category_name,
            subCategory: remote.sub_category_name,
            stateOfRepair: remote.state_of_repair_name,
            handoverStandard: remote.handover_standard_name,
            generalDescription: remote.general_description,
            floors: remote.floor_location,
            numberOfUnits: remote.number_of_units,
            parkingType: remote.parking_type_name,
            automation: remote.automation
        },
        sizes: mapVAssetClassSizeRemoteToDomain(remote.sizes),
        considerations,
        displayConsiderations: displayConsiderations(considerations),
        status: remote.status,
        usedTimes: remote.used_times,
        propertySubTypeName: remote.property_sub_type_name,
        propertyTypeName: remote.property_type_name,
        pictures: remote.pictures,
        parcelIdentification: mapVParcelIdentificationRemoteToDomain(remote.parcel_identification),
        building: remote.buildingInfo,
        oldBuildingInfo:remote.oldBuildingInfo,
        esgIndicator: mapRemoteESGIndicatorToModel(remote.esgIndicator)
    }
}