import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AssetClassModel, ValuationModel } from 'src/app/core/asset_class';
import { currentUser } from 'src/app/core/mad-auth/mad-auth.store';
import { ValuationProcessService } from 'src/app/core/valuation-process';

@Component({
  selector: 'kt-summary-table-v2',
  templateUrl: './summary-table-v2.component.html',
  styleUrls: ['./summary-table-v2.component.scss']
})
export class SummaryTableV2Component implements OnInit {
  @Input()
  targetProperty: AssetClassModel;
  @Input()
  valuation: ValuationModel;

  viewModel$: Observable<any>;


  constructor(
    private valuationProcessService: ValuationProcessService,
    private store: Store
  ) { }

  ngOnInit(): void {
    this.viewModel$ = this.valuationProcessService.summaryData(this.targetProperty.id, this.valuation.id)
  }

}
