<div class="row">
    <div class="col-12">
        <h5 class="my-3 text-mad text-uppercase">departure</h5>
        <hr class="active">
    </div>
</div>
<div class="row" [ngClass]="{'readonly': readonly}">
    <div class="col-12">
        <div class="mb-4">
            <mat-label>
                Advice provided by a valuer that is contrary to a specific provision in VPSs 1-6 that is not mandatory within the relevant context or jurisdiction nor within the specific exceptions in PS 1 section 5
            </mat-label>
        </div>
    </div>
    <div class="col-12">
        <div class="py-1" *ngFor="let item of departuresData; let i = index">
            <mat-checkbox [checked]="item.selected" (change)="changeSelection($event, i)">
                <span *ngIf="item.name.toLowerCase() == 'none'">
                    {{item.name}}
                </span>
                <span *ngIf="item.name.toLowerCase() != 'none' ">
                    <b>{{item.valuation_standard_name}}</b> - {{item.name}}
                </span>
            </mat-checkbox>
        </div>
    </div>
    <div class="col-12">
        <a href="javascript:;" class="bnt bnt-primary mr-2 mt-2" color="primary"
            mat-raised-button
            *ngIf="!readonly"
            matTooltip="Add Departure"
            (click)="addItem()">
            <span>Add Departure</span> 
        </a> 
    </div> 
</div>
