import { ValuationProcessTargetPropertyRemote } from "../_services/valuation-process.service"
import { ESGIndicator, ESGIndicatorRemote, mapRemoteESGIndicatorToModel } from "./esg-indicator.model"
import { mapVApartmentGroundRemoteToDomain } from "./valuation-apartment.model"
import { displayConsiderations, getGarageRange, mapConsiderationRemoteToDomain, mapVAssetClassSizeRemoteToDomain, mapVLocationRemoteToDomain, VAssetClassConsideration, VAssetClassConsiderationRemote, VAssetClassSize, VAssetClassSizeRemote, VExternalAspectRemote, VGroundRemote, VInternalAspectRemote, VLocationData, VLocationDataRemote } from "./valuation-asset-class-common.models"
import { mapVParcelIdentificationRemoteToDomain, VParcelIdentification, VParcelIdentificationRemote } from "./valuation-land-parcel.model"
import { PictureModel, TypeOfInspection, ValuationProcessRetailBuildingModel } from "./valuation-process-target-property.model"

export type VRetailBuildingModel = {
    refNum: string,
    propertySubType: 'Retail Building'
    propertySubTypeName: string

    locationData: VLocationData
    property: VRetailBuildingAboutProperty
    externalAspect: VRetailBuildingExternalAspect,
    internalAspect: VRetailBuildingInternalAspect,
    ground: VRetailBuildingGround

    pictures: PictureModel[]
    sizes: VAssetClassSize[]

    parcelIdentification: VParcelIdentification,

    esgIndicator: ESGIndicator
}

export type VRetailBuildingAboutProperty = {
    subTypeCategory: string,
    subCategory: string,
    stateOfRepair: string,
    handoverStandard: string,
    aboveFloors: number,
    belowFloors: number,
    energyEfficiencyGrade: string,
    generalDescription: string,
    constructionDescription: string,
    propertyGrade: string,
    completionYear: string,
    approximateYear: string,
    foundationType: string
}

export type VRetailBuildingInternalAspect = {
    ceiling: string,
    ceilingComment: string,
    wallsAndPartition: string,
    wallsAndPartitionComment: string,
    floors: string,
    floorsComment: string,
    roofStructure: string,
    roofStructureComment: string,
    shopFrontType: string,
    shopFrontTypeComment: string,
    height: string,
    heightComment: string,
    cubicContent: string,
    cubicContentComment: string,
    builtInFittings: string,
    others: string
}
export type VRetailBuildingExternalAspect = {
    roofCovering: string,
    roofCoveringComment: string,
    rainwaterPipe: string,
    rainwaterPipeComment: string,
    mainWall: string,
    mainWallComment: string,
    window: string,
    windowComment: string,
    outsideDoor: string,
    outsideDoorComment: string,
    loadingDock: string,
    others: string
}
export type VRetailBuildingGround = {
    indoorGarage: string
    indoorGarageComment: string,
    outdoorGarage: string,
    outdoorGarageComment: string,
    outdoorSpace: string,
    outdoorSpaceComment: string
}

export type VCRetailBuildingModel = VRetailBuildingModel & {
    id: number

    considerations: VAssetClassConsideration[]
    displayConsiderations: VAssetClassConsideration[]

    status: number
    usedTimes: number
    propertyTypeName: string
}

export type VTPRetailBuildingModel = VRetailBuildingModel & {
    refNum: 'tp',
    inspectionInfo: {
        typeOfInspection: TypeOfInspection
        inspectionDate: Date
        anyLimitationOrRestriction: boolean,
        limitationDescription: string 
    },
}

export function mapVTPRetailBuildingRemoteToDomain(tpRemote: ValuationProcessTargetPropertyRemote, generalInfo: ValuationProcessRetailBuildingModel): VTPRetailBuildingModel {
    return {
        refNum: 'tp',
        propertySubType: generalInfo.propertySubType,
        propertySubTypeName: tpRemote.propertySubTypeName,
        locationData: {
            latitude: generalInfo.locationData.latitude,
            longitude: generalInfo.locationData.longitude,
            country: generalInfo.locationData.countryName,
            city: generalInfo.locationData.cityName,
            zipCode: generalInfo.locationData.zipCode,
            address: generalInfo.locationData.address,
            locationGrade: generalInfo.locationGrade == 'N/A' ? 'Unknown' : generalInfo.locationGrade,
            locationSurrounding: tpRemote.locationSurrounding,
            timezoneOffset: tpRemote.locationData?.timezoneOffset
        },
        inspectionInfo: {
            typeOfInspection: tpRemote.inspectionTypeId,
            inspectionDate: new Date(tpRemote.inspectionDate),
            anyLimitationOrRestriction: tpRemote.anyLimitationsOrRestrictions,
            limitationDescription: tpRemote.limitationDesc
        },
        property: {
            subTypeCategory: tpRemote.subTypeCategoryName == 'N/A' ? null : tpRemote.subTypeCategoryName,
            subCategory: tpRemote.subCategoryName == 'N/A' ? null : tpRemote.subCategoryName,
            stateOfRepair: tpRemote.aboutProperty.stateOfRepairName,
            handoverStandard: tpRemote.aboutProperty.handoverStandardName,
            aboveFloors: Number(tpRemote.aboutProperty.above_floors),
            belowFloors: Number(tpRemote.aboutProperty.below_floors),
            energyEfficiencyGrade: tpRemote.aboutProperty.energyEfficiencyGradeName,
            generalDescription: tpRemote.aboutProperty.generalDescription,
            constructionDescription: tpRemote.aboutProperty.construction_desc,
            propertyGrade: tpRemote.aboutProperty.property_grade_name,
            completionYear: tpRemote.aboutProperty.completion_year,
            approximateYear: tpRemote.aboutProperty.apprx_year_extended,
            foundationType: tpRemote.aboutProperty.foundation_type_name
        },
        parcelIdentification: mapVParcelIdentificationRemoteToDomain(tpRemote.parcel_identification),
        pictures: tpRemote.pictures,
        internalAspect: mapVRetailBuildingInternalAspectRemoteToDomain(tpRemote.internalAspect),
        externalAspect: mapVRetailBuildingExternalAspectRemoteToDomain(tpRemote.externalAspect),
        ground: mapVRetailBuildingGroundRemoteToDomain(tpRemote.ground),
        sizes: mapVAssetClassSizeRemoteToDomain(tpRemote.sizes),
        esgIndicator: mapRemoteESGIndicatorToModel(tpRemote.esgIndicator)
    }
}

export type VCRetailBuildingRemote = {
    id: number,
    property_sub_type_id: 11;

    // Info
    ref_num: string,
    location_data: VLocationDataRemote,
    sub_type_category_name: string,
    sub_category_name: string,
    property_grade_name: string,
    general_description: string,
    state_of_repair_name: string,
    handover_standard_name: string,
    foundation_type_name: string,
    above_floors: string,
    below_floors: string,
    energy_efficiency_grade_name: string,
    construction_desc: string,
    completion_year: string,
    apprx_year_extended: string,
    external_aspect: VExternalAspectRemote
    internal_aspect: VInternalAspectRemote,
    ground: VGroundRemote

    sizes: VAssetClassSizeRemote[],
    considerations: VAssetClassConsiderationRemote[],
    pictures: PictureModel[],
    parcel_identification: VParcelIdentificationRemote

    // Additional
    status: number,
    used_times: number,
    property_sub_type_name: string,
    property_type_name: string,
    esgIndicator: ESGIndicatorRemote
}

export function mapVCRetailBuildingRemoteToDomain(remote: VCRetailBuildingRemote): VCRetailBuildingModel {
    const considerations = mapConsiderationRemoteToDomain(remote.considerations)
    return {
        id: remote.id,
        refNum: remote.ref_num,
        propertySubType: 'Retail Building',
        locationData: mapVLocationRemoteToDomain(remote.location_data),
        property: {
            subTypeCategory: remote.sub_type_category_name,
            subCategory: remote.sub_category_name,
            stateOfRepair: remote.state_of_repair_name,
            handoverStandard: remote.handover_standard_name,
            aboveFloors: Number(remote.above_floors),
            belowFloors: Number(remote.below_floors),
            energyEfficiencyGrade: remote.energy_efficiency_grade_name,
            generalDescription: remote.general_description,
            constructionDescription: remote.construction_desc,
            propertyGrade: remote.property_grade_name,
            completionYear: remote.completion_year,
            approximateYear: remote.apprx_year_extended,
            foundationType: remote.foundation_type_name
        },
        externalAspect: mapVRetailBuildingExternalAspectRemoteToDomain(remote.external_aspect),
        internalAspect: mapVRetailBuildingInternalAspectRemoteToDomain(remote.internal_aspect),
        ground: mapVRetailBuildingGroundRemoteToDomain(remote.ground),
        sizes: mapVAssetClassSizeRemoteToDomain(remote.sizes),
        considerations,
        displayConsiderations: displayConsiderations(considerations),
        status: remote.status,
        usedTimes: remote.used_times,
        propertySubTypeName: remote.property_sub_type_name,
        propertyTypeName: remote.property_type_name,
        pictures: remote.pictures,
        parcelIdentification: mapVParcelIdentificationRemoteToDomain(remote.parcel_identification),
        esgIndicator: mapRemoteESGIndicatorToModel(remote.esgIndicator)
    }
}

function mapVRetailBuildingExternalAspectRemoteToDomain(remote: VExternalAspectRemote): VRetailBuildingExternalAspect {
    return {
        roofCovering: remote.roof_covering_name,
        roofCoveringComment: remote.roof_covering_comment,
        rainwaterPipe: remote.rainwater_pipe_name,
        rainwaterPipeComment: remote.rainwater_pipe_comment,
        mainWall: remote.main_wall_name,
        mainWallComment: remote.main_wall_comment,
        window: remote.window_type_name,
        windowComment: remote.window_type_comment,
        outsideDoor: remote.outside_door_name,
        outsideDoorComment: remote.outside_door_comment,
        loadingDock: remote.loading_dock ? `Yes. ${remote.loading_dock_comment ?? ''}` : 'No',
        others: remote.has_other ? remote.other_aspects : null
    }
}

function mapVRetailBuildingInternalAspectRemoteToDomain(remote: VInternalAspectRemote): VRetailBuildingInternalAspect {
    return {
        ceiling: remote.ceiling_name,
        ceilingComment: remote.ceiling_comment,
        wallsAndPartition: remote.walls_and_partition_name,
        wallsAndPartitionComment: remote.walls_and_partition_comment,
        floors: remote.floor_type_name,
        floorsComment: remote.floor_type_name,
        roofStructure: remote.roof_structure_name,
        roofStructureComment: remote.roof_structure_comment,
        builtInFittings: remote.builtin_fitting_names,
        height: remote.height,
        heightComment: remote.height_comment,
        cubicContent: remote.cubic_content,
        cubicContentComment: remote.cubic_content_comment,
        shopFrontType: remote.shop_front_type_name,
        shopFrontTypeComment: remote.shop_front_type_comment,
        others: remote.has_other ? remote.other_aspects : null
    }
}

function mapVRetailBuildingGroundRemoteToDomain(remote: VGroundRemote): VRetailBuildingGround {
    return {
        indoorGarage: getGarageRange(remote.indoor_garage),
        indoorGarageComment: remote.indoor_garage_comment,
        outdoorGarage: getGarageRange(remote.outdoor_garage),
        outdoorGarageComment: remote.outdoor_garage_comment,
        outdoorSpace: remote.outdoor_space_name,
        outdoorSpaceComment: remote.outdoor_space_comment
    }
}