<div class="card card-custom">
    <div class="card-header">
        <div class="card-title">
            <h4 class="card-label">{{getTitle()}}</h4>
        </div>
    </div>
    <div class="form kt-form--label-align-right form-group-seperator-dashed">
        <div class="card-body pt-0 w-100">
            <mat-dialog-content>
                <form [formGroup]="form" class="form" *ngIf="form">
                    <kt-alert *ngIf="hasFormErrors" type="warn" [duration]="30000" [showCloseButton]="true" (close)="onAlertClose($event)">
                        Fill the required fields.
                    </kt-alert>

                    <kt-alert *ngIf="isDuplicate" type="warn">
                        This Target Property cannot be added. Target Property with a same name and property sub type still exists.
                    </kt-alert>

                    <div [class]="viewMode ? 'pointer-none':''">
                        <div class="form-group row">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">target property information</h5>
                                <hr class="active">
                            </div>

                            <div class="col-12 warning mb-4" *ngIf="customTp$|async">
                                <p class="message">
                                    You have selected a custom property type. As a result, most of the inspection and valuation features will be disabled and set to manual operation.
                                </p>
                            </div>

                            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput required placeholder="Target Property ID" formControlName="name"/>
                                    <mat-error>
                                        Target Property ID
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Surface Area{{data.measurement.name}}" formControlName="surface" mask="separator.2"/>
                                    <mat-icon
                                            matSuffix
                                            class="cursor-pointer icon-gray"
                                            container="body"
                                            [ngbPopover]="'Enter the surface area of the property. If the size is unknown, please input \'0\'. Note that this will disable the pricing strategy based on property size.'"
                                            (click)="$event.stopPropagation();">help
                                    </mat-icon>
                                    <mat-error>
                                        Surface Area
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select required placeholder="Property Type" formControlName="target_property_type_id">
                                        <mat-select-trigger>
                                            <span class="text-danger font-weight-bold" *ngIf="(customTp$|async); else regular">
                                                Custom
                                            </span>
                                            <ng-template #regular>
                                                <span>
                                                    {{getTopPropertyTypeName(form.controls.target_property_type_id.value)}}
                                                </span>
                                            </ng-template>
                                        </mat-select-trigger>
                                        <mat-option *ngFor="let type of topPropertyTypes" [value]="type.id">
                                            {{type.title}}
                                        </mat-option>
                                        <mat-option [value]="-1">
                                            <span class="text-danger font-weight-bold">Custom</span>
                                        </mat-option>
                                    </mat-select>
                                    <mat-icon
                                            *ngIf="(customTp$|async)"
                                            matSuffix
                                            class="cursor-pointer icon-gray"
                                            container="body"
                                            [popoverTitle]="'TOOLTIP.TP.CUSTOM_ASSET_CLASS.TITLE' | translate"
                                            [ngbPopover]="'TOOLTIP.TP.CUSTOM_ASSET_CLASS.DESCRIPTION' | translate"
                                            (click)="$event.stopPropagation();">help
                                    </mat-icon>
                                    <mat-error>
                                        Property Type
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-10-mobile" *ngIf="(customTp$|async)">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput required placeholder="Property Type Name" formControlName="custom_asset_class_name"/>
                                    <mat-error>
                                        Property Type Name
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-10-mobile" 
                                *ngIf="
                                    form.controls.target_property_type_id.value && 
                                    form.controls.target_property_type_id.value != propertyTypes.Custom">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select required placeholder="Property Sub-Type" formControlName="property_type_id">
                                        <mat-option *ngFor="let propertyType of filteredPropertySubTypes" [value]="propertyType.id">{{propertyType.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Property Sub-Type
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-10-mobile" *ngIf="(customTp$|async)">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input required matInput placeholder="Property Sub-Type" formControlName="custom_property_type"/>
                                    <mat-icon matSuffix class="cursor-pointer icon-gray" container="body" (click)="$event.stopPropagation()"
                                        [popoverTitle]="'TOOLTIP.TP.CUSTOM_PROPERTY_SUB_TYPE.TITLE' | translate"
                                        [ngbPopover]="'TOOLTIP.TP.CUSTOM_PROPERTY_SUB_TYPE.DESCRIPTION' | translate">
                                        help
                                    </mat-icon>
                                    <mat-error>
                                        Property Sub-Type Name
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-6">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select placeholder="Floor Numbering Scheme" formControlName="floor_numbering_scheme_id">
                                                <mat-option *ngFor="let item of floorNumberingSchemes" [value]="item.id">
                                                    {{item.name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-icon
                                                    matSuffix
                                                    class="cursor-pointer icon-gray"
                                                    container="body"
                                                    popoverClass="my-popover"
                                                    [ngbPopover]="floorNumberingSchemeBody"
                                                    (click)="$event.stopPropagation();">help
                                            </mat-icon>
                                        </mat-form-field>
                                        <div class="info-message" *ngIf="form.controls.floor_numbering_scheme_id.value == 1">
                                            The ground storey is identified as the floor 0
                                        </div>
                                        <div class="info-message" *ngIf="form.controls.floor_numbering_scheme_id.value == 2">
                                            The ground storey is identified as the 1st floor
                                        </div>
                                    </div>
                                    <div class="col-6" *ngIf="data.purposeOfValuationID == 12 || data.purposeOfValuationID == 13">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select placeholder="Asset classification / use by the reporting entity" formControlName="asset_classification_id">
                                                <mat-option *ngFor="let item of assetClassifications$|async" [value]="item.id">
                                                    {{ item.name }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                Asset classification is <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="showFloorLocation" class="col-lg-12 kt-margin-bottom-10-mobile m-0 px-2">
                                <div class="d-inline-flex align-baseline w-100">
                                    <tag-input id="tp-tag" class="tag mat-form-field-infix" matInput formControlName="floors_valuated"
                                               placeholder="+ Floor location (enter number (3) or range of number (ex: '2:4') and hit enter)"
                                               secondaryPlaceholder="+ Floor location (enter number (3) or range of number (ex: '2:4') and hit enter) *"
                                               modelAsStrings="true"
                                               [validators]="floorValidators"
                                               style="width: 100%"
                                    >
                                    </tag-input>
                                    <mat-icon
                                            matSuffix
                                            class="cursor-pointer icon-gray"
                                            container="body"
                                            [popoverTitle]="'TOOLTIP.TP.FLOOR_LOCATION.TITLE' | translate"
                                            [ngbPopover]="'TOOLTIP.TP.FLOOR_LOCATION.DESCRIPTION' | translate"
                                            (click)="$event.stopPropagation();">help
                                    </mat-icon>
                                </div>
                                <mat-error class="tag-custom-error" *ngIf="form.controls['floors_valuated'].touched && form.controls['floors_valuated'].hasError('tagRequired')">
                                    Floor location <strong>required</strong>
                                </mat-error>
                            </div>
                            <div class="col-lg-12 kt-margin-bottom-10-mobile" *ngIf="(customTp$|async)">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Floor location" formControlName="custom_floor_valuated"/>
                                    <mat-icon matSuffix class="cursor-pointer icon-gray" container="body" (click)="$event.stopPropagation()"
                                        [popoverTitle]="'TOOLTIP.TP.CUSTOM_FLOOR_LOCATION.TITLE' | translate"
                                        [ngbPopover]="'TOOLTIP.TP.CUSTOM_FLOOR_LOCATION.DESCRIPTION' | translate">
                                        help
                                    </mat-icon>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-12">
                                <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom"
                                    (mapReady)="onMapReady($event)"
                                    (centerChange)="onCenterChange($event)"
                                    [scrollwheel]="null"
                                    [usePanning]="true"
                                    [clickableIcons]="false">
                                    <agm-map-type-control></agm-map-type-control>
                                    <button type="button" id="Profile" class="btn btn-light btn-icon mr-2 map-button" (click)="setLocation()">
                                        <mat-icon>my_location</mat-icon>
                                    </button>
                                    <agm-marker
                                        [latitude]="markerLat" [longitude]="markerLng" [markerDraggable]="false">
                                    </agm-marker>
                                </agm-map>
                            </div>
                            <div class="col-lg-12 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea required matInput placeholder="Address" rows="2" formControlName="address"></textarea>
                                    <mat-error>
                                        Address
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select required placeholder="Country" formControlName="country_id" (selectionChange)="selectCountry()">
                                    <mat-option>
                                        <div (click)="addCountry()" class="add-entry">
                                            <i class="ki ki-plus"></i> New country
                                        </div>
                                    </mat-option>
                                        <mat-option *ngFor="let country of allCountries" [value]="country.id">{{country.name}}</mat-option>
                                    </mat-select>
                                    <mat-error>Country
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-20-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select required placeholder="City" formControlName="city_id">
                                        <mat-option *ngIf="form.controls.country_id.value">
                                            <div (click)="addCity()" class="add-entry">
                                                <i class="ki ki-plus"></i> New city
                                            </div>
                                        </mat-option>
                                        <mat-option *ngFor="let city of cityOfCountry" [value]="city.id">{{city.name}}</mat-option>
                                        <mat-option *ngIf="cityOfCountry.length == 0" disabled>Please add city to Cities List</mat-option>
                                    </mat-select>
                                    <mat-error>City
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Zipcode/Postalcode" formControlName="zip_code"/>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="form-group row">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">Valuation & Investigation Dates</h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                <!-- <kt-datepicker-with-timezone [label]="'Date of Investigation'" [value]="null"></kt-datepicker-with-timezone> -->
                                <mat-form-field class="mat-form-field-fluid">
                                    <kt-datepicker-tz-input 
                                        #investigationDatePicker 
                                        placeholder="Date of investigation" 
                                        formControlName="inspection_date" 
                                        [timezone]="timezoneOffset"
                                        [max]="draftDate ? getPrevDate(draftDate) : getPrevDate(finalDate)"
                                        (dateChange)="changeInspectionDate()"
                                    ></kt-datepicker-tz-input>
                                    <button mat-button mat-icon-button matSuffix type="button">
                                        <mat-icon>today</mat-icon>
                                    </button>
                                    <button mat-button mat-icon-button matSuffix type="button" (click)="clearInspectionDate(investigationDatePicker)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-error>
                                        Date of investigation required
                                    </mat-error>
                                </mat-form-field>
                                <!-- <mat-form-field class="mat-form-field-fluid" 
                                        [ngClass]="{'mat-form-field-invalid': inspectionDateCtlr.invalid && (inspectionDateCtlr.dirty || inspectionDateCtlr.touched) }">
                                    <span (click)="addPicker_inspection_date.open()" style="position: relative">
                                    <input matInput 
                                        readonly 
                                        required 
                                        [value]="inspectionDate ? (inspectionDate | dateWithOffset:timezoneOffset) : null" 
                                        placeholder="Date of investigation (Expected)"/>
                                    <input required matInput [matDatepicker]="addPicker_inspection_date" (click)="addPicker_inspection_date.open()"
                                           readonly
                                           class="shadow"
                                           placeholder="Date of investigation (Expected)"
                                           formControlName="inspection_date"
                                           [max]="draftDate ? getPrevDate(draftDate) : getPrevDate(finalDate)"
                                           (dateChange)="changeInspectionDate()">
                                    </span>
                                    <mat-datepicker-toggle matSuffix [for]="addPicker_inspection_date"></mat-datepicker-toggle>
                                    <button type="button" mat-button *ngIf="this.form.controls.inspection_date.value" matSuffix mat-icon-button matTooltip="Clear"
                                            (click)="clearInspectionDate()">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-datepicker #addPicker_inspection_date></mat-datepicker>
                                    <mat-icon
                                        *ngIf="form.controls.type_of_inspection.value == 1"
                                            matSuffix
                                            class="cursor-pointer icon-gray"
                                            container="body"
                                            [popoverTitle]="'TOOLTIP.TP.DATE_OF_INVESTIGATION.TITLE' | translate"
                                            [ngbPopover]="'TOOLTIP.TP.DATE_OF_INVESTIGATION.DESCRIPTION' | translate"
                                            (click)="$event.stopPropagation();">help
                                    </mat-icon>
                                    <mat-hint class="tag-custom-error" *ngIf="inspectionDateCtlr.invalid && (inspectionDateCtlr.dirty || inspectionDateCtlr.touched)">
                                        Date of Investigation
                                        <strong>required</strong>
                                    </mat-hint>
                                </mat-form-field> -->
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <kt-datepicker-tz-input 
                                        #draftDatePicker 
                                        placeholder="Date of Draft Delivery (Expected)" 
                                        formControlName="draft_date" 
                                        [timezone]="timezoneOffset"
                                        [min]="getNextDate(inspectionDate)"
                                        [max]="getPrevDate(finalDate)"
                                        (dateChange)="changeDraftDate()"
                                    ></kt-datepicker-tz-input>
                                    <button mat-button mat-icon-button matSuffix type="button">
                                        <mat-icon>today</mat-icon>
                                    </button>
                                    <button mat-button mat-icon-button matSuffix type="button" (click)="clearDraftDate(draftDatePicker)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <!-- <span (click)="addPicker_draft_date.open()">
                                    <input matInput [matDatepicker]="addPicker_draft_date" (click)="addPicker_draft_date.open()"
                                           readonly
                                           placeholder="Date of Draft Delivery (Expected)"
                                           formControlName="draft_date"
                                           #refEl
                                           [min]="getNextDate(inspectionDate)"
                                           [max]="getPrevDate(finalDate)"
                                           (dateChange)="changeDraftDate()">
                                    </span>
                                    <mat-datepicker-toggle matSuffix [for]="addPicker_draft_date"></mat-datepicker-toggle>
                                    <button type="button" mat-button *ngIf="this.form.controls.draft_date.value" matSuffix mat-icon-button matTooltip="Clear" (click)="clearDate(this.form.controls.draft_date)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-datepicker #addPicker_draft_date></mat-datepicker>
                                    <mat-error>
                                        Date of Draft Delivery
                                        <strong>required</strong>
                                    </mat-error> -->
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <!-- <span (click)="addPicker_valuation_date.open()">
                                    <input required matInput [matDatepicker]="addPicker_valuation_date" (click)="addPicker_valuation_date.open()"
                                           readonly
                                           placeholder="Date of Final Report (Expected)"
                                           formControlName="valuation_date"
                                           [min]="draftDate ? getNextDate(draftDate) : getNextDate(inspectionDate)"
                                           (dateChange)="changeFinalDate()">
                                    </span>
                                    <mat-datepicker-toggle matSuffix [for]="addPicker_valuation_date"></mat-datepicker-toggle>
                                    <button type="button" mat-button *ngIf="this.form.controls.valuation_date.value" matSuffix mat-icon-button matTooltip="Clear"
                                            (click)="clearDate(this.form.controls.valuation_date)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-datepicker #addPicker_valuation_date></mat-datepicker> -->
                                    <kt-datepicker-tz-input 
                                        #finalDatePicker 
                                        placeholder="Date of Final Report (Expected)" 
                                        formControlName="valuation_date" 
                                        [timezone]="timezoneOffset"
                                        [min]="draftDate ? getNextDate(draftDate) : getNextDate(inspectionDate)"
                                        (dateChange)="changeFinalDate()"
                                    ></kt-datepicker-tz-input>
                                    <button mat-button mat-icon-button matSuffix type="button">
                                        <mat-icon>today</mat-icon>
                                    </button>
                                    <button mat-button mat-icon-button matSuffix type="button" (click)="clearFinalDate(finalDatePicker)">
                                        <mat-icon>close</mat-icon>
                                    </button>
                                    <mat-error>
                                        Date of Final Report
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group row section-margin-top-60">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">EXTENT OF INVESTIGATION, LIMITATIONS AND RESTRICTIONS DURING THE INSPECTION</h5>
                                <hr class="active">
                            </div>

                            <div class="col-lg-12 container kt-margin-bottom-20-mobile" *ngFor="let radio of radios; let i = index">
                                <div class="row mb-4 mt-4" *ngIf="radio.show">
                                    <mat-label>
                                        {{radio.label}}

                                        <mat-icon
                                                *ngIf="radio.tooltip_key"
                                                class="cursor-pointer icon-gray"
                                                [popoverTitle]="'TOOLTIP.TP.'+radio.tooltip_key+'.TITLE' | translate"
                                                [ngbPopover]="'TOOLTIP.TP.'+radio.tooltip_key+'.DESCRIPTION' | translate"
                                                (click)="$event.stopPropagation();">help
                                        </mat-icon>
                                    </mat-label>
                                </div>
                                <mat-radio-group class="mt-4 mb-2" [formControlName]="radio.formControlName" *ngIf="radio.show && i != 5">
                                    <mat-radio-button class="mr-2"
                                                      *ngFor="let decision of radio.decisions"
                                                      [value]="decision.value">
                                        {{decision.label}}
                                    </mat-radio-button>
                                </mat-radio-group>
                                <mat-radio-group class="mt-4 mb-2" [formControlName]="radio.formControlName" *ngIf="radio.show && i == 5">
                                    <mat-radio-button class="col-lg-3"
                                                      *ngFor="let decision of radio.decisions" [value]="decision.value"
                                                      [disabled]="decision.disabled">
                                        {{decision.label}}
                                    </mat-radio-button>
                                </mat-radio-group>
                                <mat-form-field class="mat-form-field-fluid mt-4" *ngIf="form.controls[radio.formControlName].value == radio.triggerFlag && radio.triggerField == 'descr'">
                                    <textarea required matInput [placeholder]="radio.descr_label" rows="2" [formControlName]="radio.required[0]"></textarea>
                                    <mat-error>
                                        {{radio.descr_label}}
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                                <div class="row align-items-center container mt-4">
                                    <div class="col-lg-4 kt-margin-bottom-10-mobile" *ngIf="radio.triggerField == 'combos'">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select required placeholder="Measurement Standard" [formControlName]="radio.required[0]">
                                                <mat-option [value]="-1" *ngIf="form.controls[radio.formControlName].value == radio.triggerFlag">
                                                    Custom
                                                </mat-option>
                                                <mat-option *ngFor="let standardMeasurement of filteredstandardMeasurements" [value]="standardMeasurement.id">{{standardMeasurement.name}}</mat-option>
                                            </mat-select>

                                            <mat-icon
                                                    matSuffix
                                                    class="cursor-pointer icon-gray"
                                                    container="body"
                                                    [popoverTitle]="'TOOLTIP.TP.MEASUREMENT.TITLE' | translate"
                                                    [ngbPopover]="'TOOLTIP.TP.MEASUREMENT.DESCRIPTION' | translate"
                                                    (click)="$event.stopPropagation();">help
                                            </mat-icon>
                                            <mat-error>
                                                Measurement Standard
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-lg-4 kt-margin-bottom-10-mobile" *ngIf="form.controls.standard_measurement_id.value == -1 && radio.triggerField == 'combos'">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <input matInput placeholder="Measurement Standards Name" formControlName="new_standard_measurement_name" />
                                            <mat-error>
                                                Measurement Standard Name
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-lg-4 kt-margin-bottom-10-mobile" *ngIf="form.controls[radio.formControlName].value == radio.triggerFlag && radio.triggerField == 'combos'">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select required placeholder="Measurement Methodology" [formControlName]="radio.required[1]">
                                                <mat-option [value]="0">-</mat-option>
                                                <mat-option *ngFor="let measurementMethodology of measurementMethodologies" [value]="measurementMethodology.id">{{measurementMethodology.name}}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error>
                                                Measurement Methodology
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>

                                    <div class="col-lg-4 kt-margin-bottom-10-mobile" *ngIf="form.controls[radio.formControlName].value == radio.triggerFlag && radio.triggerField == 'combos'">
                                        <mat-form-field class="mat-form-field-fluid">
                                            <mat-select required placeholder="Purpose of Measurement" [formControlName]="radio.required[2]">
                                                <mat-option [value]="0">-</mat-option>
                                                <mat-option *ngFor="let purposeMeasurement of purposeMeasurements" [value]="purposeMeasurement.id">{{purposeMeasurement.name}}</mat-option>
                                            </mat-select>
                                            <mat-icon
                                                    matSuffix
                                                    class="cursor-pointer icon-gray"
                                                    container="body"
                                                    [popoverTitle]="'TOOLTIP.TP.PURPOSE.TITLE' | translate"
                                                    [ngbPopover]="'TOOLTIP.TP.PURPOSE.DESCRIPTION' | translate"
                                                    (click)="$event.stopPropagation();">help
                                            </mat-icon>
                                            <mat-error>
                                                Purpose of Measurement
                                                <strong>required</strong>
                                            </mat-error>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-12 kt-margin-bottom-20-mobile mt-4 mb-2" *ngIf="form.controls.intended_lender.value == '0'">
                                <div class="mb-4">
                                    <mat-label>
                                        9A - Are there any specific requirements made by the intended lender?
                                    </mat-label>
                                </div>
                                <div class="mb-4">
                                    <mat-radio-group class="" formControlName="specific_req_on_intended_lender">
                                        <mat-radio-button class=" kt-margin-r-20"
                                                          *ngFor="let decision of decisionsExtra" [value]="decision.value">
                                            {{decision.label}}
                                        </mat-radio-button>
                                    </mat-radio-group>
                                </div>
                            </div>

                            <div class="col-lg-12 kt-margin-bottom-20-mobile mb-4" *ngIf="form.controls.specific_req_on_intended_lender.value == '1'">
                                <mat-form-field class="mat-form-field-fluid">
                                    <textarea matInput placeholder="Please specify" rows="2" formControlName="specific_req_on_intended_lender_descr"></textarea>
                                    <mat-error>
                                        Please specify
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>

                            <div class="col-lg-12 kt-margin-bottom-20-mobile mb-4" *ngIf="form.controls.specific_req_on_intended_lender.value == '2'">
                                <div>
                                    <mat-label class="badge badge-warning mr-2 color-white">
                                        Warning
                                    </mat-label>
                                    <mat-label>
                                        The mention “The valuation may not be acceptable to a lender” will be added mandatorily in both the Terms of Engagement and the Valuation report.
                                    </mat-label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group kt-form__group row section-margin-top-60 tag-custom-padding-16_25">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">valuation specifics</h5>
                                <hr class="active">
                            </div>

                            <div class="col-12 warning mb-4" *ngIf="hasAnyResidual">
                                <p class="message">
                                    You have chosen the residual method. Consequently, most valuation features related to this method will be disabled and switched to manual operation. We are currently developing this method, and it will be available shortly. For more information, please feel free to contact us.
                                </p>
                            </div>

                            <kt-ac-valuation-alt 
                                [customTp$]="customTp$" 
                                class="col-md-12 kt-margin-bottom-20-mobile" 
                                [(valuationsSubject)]="valuationsSubject"
                                [propertyType$]="propertyType$"
                                >
                            </kt-ac-valuation-alt>
                            <div class="col-lg-4 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input required matInput placeholder="Interest Valued" formControlName="percentage" mask="percent" suffix="%"/>

                                    <mat-icon
                                            matSuffix
                                            class="cursor-pointer icon-gray"
                                            container="body"
                                            [popoverTitle]="'TOOLTIP.TP.INTEREST_VALUED.TITLE' | translate"
                                            [ngbPopover]="'TOOLTIP.TP.INTEREST_VALUED.DESCRIPTION' | translate"
                                            (click)="$event.stopPropagation();">help
                                    </mat-icon>
                                    <mat-error>
                                        Interest Valued
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 kt-margin-bottom-10-mobile" *ngIf="form.controls.percentage.value && form.controls.percentage.value < 100">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Expected Restricted Interest Discount (if any)" formControlName="discount" mask="percent" suffix="%"/>
                                    <mat-icon
                                            matSuffix
                                            class="cursor-pointer icon-gray"
                                            container="body"
                                            [popoverTitle]="'TOOLTIP.TP.INTEREST_DISCOUNT.TITLE' | translate"
                                            [ngbPopover]="'TOOLTIP.TP.INTEREST_DISCOUNT.DESCRIPTION' | translate"
                                            (click)="$event.stopPropagation();">help
                                    </mat-icon>
                                    <mat-error>
                                        Expected Restricted Interest Discount
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-12 kt-margin-bottom-10-mobile" *ngIf="form.controls.percentage.value && form.controls.percentage.value < 100">
                                <mat-form-field class="mat-form-field-fluid">
                                <textarea required matInput
                                          placeholder="Clarify the relationship of the fractional interest being valued relative to all other fractional interests and the obligations of the fractional interest ownership, if any, to other fractional interest owners."
                                          rows="2" formControlName="discount_descr"></textarea>
                                    <mat-icon
                                            matSuffix
                                            class="cursor-pointer icon-gray"
                                            container="body"
                                            [popoverTitle]="'TOOLTIP.TP.RELATIONSHIP_OF_FRACTIONAL.TITLE' | translate"
                                            [ngbPopover]="'TOOLTIP.TP.RELATIONSHIP_OF_FRACTIONAL.DESCRIPTION' | translate"
                                            (click)="$event.stopPropagation();">help
                                    </mat-icon>
                                    <mat-error>
                                        Clarify the relationship of the fractional...
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && valuationsSubject.value.length == 0">
                                    At least 1 Valuation
                                    <strong>required</strong>
                                </mat-error>
                                <mat-error class="tag-custom-error" *ngIf="hasFormErrors && valuationValidationError">
                                    Invalid valuation(s)
                                </mat-error>
                            </div>
                        </div>
                        <div class="form-group row section-margin-top-60 tag-custom-padding-16_25">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">USE OF CURRENCIES</h5>
                                <hr class="active">
                            </div>
                            <div class="col-lg-6 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select required placeholder="Country Currency" formControlName="country_currency" #countryCurrency (selectionChange)="selectCurrency()">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="filterCtrlCountriesData"
                                                                   placeholderLabel="Search"
                                                                   noEntriesFoundLabel="Country Currencies not found">

                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let _data of filteredCountriesData | async" [value]="_data.currencyCode">
                                            {{_data.currencyCode}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Country Currency
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-6 kt-margin-bottom-10-mobile">
                                <mat-form-field class="mat-form-field-fluid">
                                    <mat-select required placeholder="Reporting Currency" formControlName="reporting_currency" #reportingCurrency (selectionChange)="selectCurrency()">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="filterCtrlReportingData"
                                                                   placeholderLabel="Search"
                                                                   noEntriesFoundLabel="Reporting Currencies not found">

                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let _data of filteredReportsData | async" [value]="_data.currencyCode">
                                            {{_data.currencyCode}}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error>
                                        Reporting Currency
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-12 kt-margin-bottom-10-mobile"
                                 *ngIf="currencyDifferent()">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input matInput placeholder="Basis of Currency Exchange Rate" formControlName="currency_exchange_rate"/>
                                    <mat-error>
                                        Basis of Currency Exchange Rate
                                        <strong>required</strong>
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="form-group row section-margin-top-60">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">ASSUMPTIONS (PART 2 GLOSSARY, RICS RED BOOK 2025)</h5>
                                <hr class="active">
                            </div>

                            <div class="col-md-12 kt-margin-bottom-20-mobile tag-custom-padding-16_25">
                                <div class="mb-4">
                                    <mat-label>
                                        A supposition taken to be true. It involves facts, conditions or situations affecting the subject of, or approach to, a valuation that, by agreement, do not
                                        need to
                                        be
                                        verified by the valuer as part of the valuation process. Typically, an assumption is made where specific investigation by the valuer is not required in order to
                                        prove
                                        that something is true. (Default and special assumption maximum quantity is 15)
                                    </mat-label>
                                </div>

                                <div class="py-1" *ngFor="let assumption of assumptionsData; let i = index">
                                    <mat-checkbox
                                            [checked]="assumption.selected"
                                            (change)="updateDefaultAssumptionCheckedOptions(i, $event)">
                                        <span>{{assumption.name}}</span>
                                    </mat-checkbox>
                                </div>
                                <a href="javascript:;"
                                   class="btn btn-primary mr-2  mt-2"
                                   color="primary"
                                   mat-raised-button
                                   (click)="addCheckbox('assumption')"
                                   matTooltip="Add a New assumption">
                                    <span class="kt-hidden-mobile"> Add Assumption</span>
                                </a>
                                <mat-error class="tag-custom-error tag-custom-margin-top-10" *ngIf="hasFormErrors && assetClassDefaultAssumptions.length == 0">
                                    At least 1 Default Assumption
                                    <strong>required</strong>
                                </mat-error>
                            </div>
                        </div>
                        <div class="form-group row section-margin-top-60">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">SPECIAL ASSUMPTIONS (PART 2 GLOSSARY, RICS RED BOOK 2025)</h5>
                                <hr class="active">
                            </div>

                            <div class="col-md-12 kt-margin-bottom-20-mobile tag-custom-padding-16_25">
                                <div class="mb-4">
                                    <mat-label>
                                        An assumption that either assumes facts that differ from the actual facts existing at the valuation date or that would not be made by a typical market
                                        participant
                                        in a
                                        transaction on the valuation date.
                                    </mat-label>
                                </div>

                                <div class="py-1" *ngFor="let specialAssumption of specialAssumptionsData; let i = index">
                                    <mat-checkbox
                                            [checked]="specialAssumption.selected"
                                            (change)="updateSpecialAssumptionCheckedOptions(i, $event)"
                                    >
                                        <span>{{specialAssumption.name}}</span>
                                    </mat-checkbox>
                                </div>
                                <a href="javascript:;"
                                   class="btn btn-primary mr-2 mt-2"
                                   color="primary"
                                   mat-raised-button
                                   (click)="addCheckbox('specialAssumption')"
                                   matTooltip="Add a New special assumption">
                                    <span class="kt-hidden-mobile"> Add Special Assumption</span>
                                </a>
                                <mat-error class="tag-custom-error tag-custom-margin-top-10" *ngIf="hasFormErrors && assetClassSpecialAssumptions.length == 0">
                                    At least 1 Special Assumption
                                    <strong>required</strong>
                                </mat-error>
                            </div>
                        </div>
                        <div class="form-group row section-margin-top-60">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <h5 class="my-3 text-mad text-uppercase">DEPARTURES</h5>
                                <hr class="active">
                            </div>

                            <div class="col-md-12 kt-margin-bottom-20-mobile tag-custom-padding-16_25">
                                <div class="mb-4">
                                    <mat-label>
                                        Advice provided by a valuer that is contrary to a specific provision in VPSs 1-6 that is not mandatory within the relevant context or jurisdiction nor within the specific exceptions in PS 1 section 5
                                    </mat-label>
                                </div>
                                <div class="py-1" *ngFor="let departure of departuresData; let i = index">
                                    <mat-checkbox
                                            [checked]="departure.selected"
                                            (change)="updateDepartureCheckedOptions(i, $event)"
                                    >
                                        <span *ngIf="departure.name.toLowerCase() == 'none'">
                                            {{departure.name}}
                                        </span>
                                        <span *ngIf="departure.name.toLowerCase() != 'none'">
                                            <b>{{ departure.valuation_standard_name }}</b> - {{departure.name}}</span>
                                    </mat-checkbox>
                                </div>
                                <a href="javascript:;"
                                   class="btn btn-primary mr-2  mt-2"
                                   color="primary"
                                   mat-raised-button
                                   (click)="addCheckbox('departure')"
                                   matTooltip="Add a New departure">
                                    <span class="kt-hidden-mobile"> Add departure</span>
                                </a>
                            </div>
                        </div>
                        <div class="form-group row section-margin-top-60">
                            <div class="col-md-12 kt-margin-bottom-20-mobile">
                                <span style="display: flex; justify-content: space-between;">
                                    <h5 class="my-3 text-mad text-uppercase">VPGA 10 – Material valuation uncertainty</h5>
                                    <button type="button" mat-raised-button
                                            color="primary"
                                            [matTooltip]="'Add New Field'"
                                            (click)="addVPGAMatterField()">
                                        Add VPGA Matter
                                    </button>
                                </span>
                                <hr class="active">
                            </div>

                            <div class="col-lg-12  kt-margin-bottom-20-mobile" *ngFor="let control of vpgaFormArray.controls; let i = index">
                                <div class="row" style="align-items: center;">
                                    <mat-form-field class="mat-form-field-fluid col-lg-11">
                                        <textarea matInput [placeholder]="'VPGA Matter (' + (i + 1) + ')'"
                                                  [formControl]="control"></textarea>
                                    </mat-form-field>
                                    <button type="button" mat-icon-button class="col-lg-1" color="warn"
                                            (click)="removeVPGAMatterField(i)">
                                        <mat-icon [matTooltip]="'Remove Field'">delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row section-margin-top-60">
                            <div class="col-lg-12 kt-margin-bottom-20-mobile pt-2">
                                <h5 class="my-3 text-mad text-uppercase">SOURCING AND EXTERNAL REFERENCES</h5>
                                <p>
                                    <strong>Here you will add the information needed to perform the valuation.</strong><br>
                                    Such information will be sourced from the client engaging us for the valuation. Should the required information to properly carry out the valuation not be
                                    available,
                                    assumptions and / or special assumptions have to be made as to the supposed nature of that information.
                                </p>
                                <hr class="active">
                            </div>
                            <kt-external-references-list class="col-md-12 kt-margin-bottom-20-mobile"
                                [isTemplate]="false"
                                                         [(sourceExternalReferencesSubject)]="sourceExternalReferencesSubject"></kt-external-references-list>
                        </div>
                    </div>
                </form>
            </mat-dialog-content>
        </div>
        <div class="card-footer b-0 p-0 text-right">
            <div class="form-actions form-actions-solid">
                <button type="button" mat-button mat-raised-button (click)="onNoClick()">Close</button>&nbsp;
                <button type="button" class="btn btn-success" mat-raised-button color="warn"
                        style="background-color: orange !important;" (click)="onSubmit(false)"
                        *ngIf="!viewMode"
                        matTooltip="Save draft">
                    Draft
                </button>&nbsp;
                <button type="button"
                        class="btn btn-success"
                        mat-raised-button
                        color="submit"
                        (click)="onSubmit(true)"
                        *ngIf="!viewMode"
                        matTooltip="Save validate">
                    Validate
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #floorNumberingSchemeBody>
    <table class="table tooltip-table">
        <thead>
            <th>Height relative to ground (storeys)</th>
            <th>European scheme</th>
            <th>North American scheme</th>
        </thead>
        <tbody>
            <tr>
                <th>Top floor</th>
                <td colspan="2">
                    Penthouse (PH), Roof (R/RF), nth floor
                </td>
            </tr>
            <tr>
                <th>2 levels above ground storey</th>
                <td>2nd floor (2/2F)</td>
                <td>3rd floor (3/3F)</td>
            </tr>
            <tr>
                <th>1 level above ground storey</th>
                <td>1st floor (1/1F)</td>
                <td>2nd floor (2/2F)</td>
            </tr>
            <tr>
                <th>Partially above ground storey</th>
                <td colspan="2">
                    Upper ground (UG), Upper level (UL), Mezzanine (M), etc.
                </td>
            </tr>
            <tr>
                <th rowspan="2">Ground storey</th>
                <td colspan="2">
                    Ground floor (G/GF), Lobby (L), Street (S)
                </td>
            </tr>
            <tr>
                <td>0th floor (0/0F)</td>
                <td>1st floor (1/1F)</td>
            </tr>
            <tr>
                <th>Partially below ground storey</th>
                <td colspan="2">
                    Lower ground (LG), Lower Level (LL), Concourse (C), Parking (P), etc.
                </td>
            </tr>
            <tr>
                <th>1 level below ground storey</th>
                <td colspan="2">1st basement (-1/-1F/B1)</td>
            </tr>
            <tr>
                <th>2 levels below ground storey</th>
                <td colspan="2">2nd basement (-2/-2F/B2)</td>
            </tr>
        </tbody>
    </table>
</ng-template>